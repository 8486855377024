<template>
  <div class="pop_mask flex flex-jc flex-ac" :style="[maskStyle]" >
    <div class="pop flex flex-col flex-jfs flex-ac" :style="[popStyle]" >
        <div class="pop_img_wrapper flex flex-jfs flex-ac" >
            <img 
                ref="img"
                class="pop_img"  
                v-for="(iItem,iIndex) in imgList" 
                :key="iIndex" 
                :src="iItem" 
                :style="{ marginLeft: !iIndex ? `-${100 * pageIndex}%` : 0 , }" 
            />
        </div>
        <div class="pop_bottom flex flex-col flex-jfs flex-ac" >
            <div  class="pop_content_wrapper flex flex-jfs flex-ac" >
                <span 
                    ref="content"
                    class="pop_content" 
                    v-for="(cItem,cIndex) in txtList" 
                    :key="cIndex" 
                    :style="{
                        textAlign: cIndex == 4 ? 'center' : 'left' ,
                        marginLeft: !cIndex ? `-${100 * pageIndex}%` : 0 ,
                    }" 
                    >{{txtList[cIndex]}}</span
                >
            </div>
            <div class="flex flex-ac" :class="[ pageIndex == 4 ? 'flex-jc' : 'flex-jsb' ]" style="width: 100%;" >
                <span v-show="pageIndex != 4" class="pop_page" >({{pageIndex+1}}/5)</span>
                <div class="flex flex-jc flex-ac" >
                    <div v-show="pageIndex != 4" class="pop_btn flex flex-jc flex-ac" @click.stop="skipHandler" >Skip</div>
                    <div 
                        class="pop_btn flex flex-jc flex-ac" 
                        :style="{padding: pageIndex !=4 ? '.69rem 0' : '.69rem 3rem'}"
                        @click.stop="nextHandler" 
                    >
                    {{ pageIndex != 4 ? 'Next' : 'View the task'}}
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props:{
        showFlag:{
            type:Boolean,
            required:true,
        }
    },
    model:{
        event: 'change',
        prop: 'showFlag'
    },
    data(){
        return {
            txtList: [
                'Join the AICEAN Movement: Watch, Share, and Earn!Earn for watching in AICEAN! Let’s get started!',
                'You can get more contribution points by watching videos and completing daily tasks.',
                'You can buy spectacles through the marketplace, and wear them to watch videos to get bonus contribution points',
                'Invite friends to participate, there will be more surprises',
                "Let's get start the magical journey of AICEAN!",
            ],
            pageIndex: 0,
        }
    },
    methods: {
        nextHandler(){
            if( this.pageIndex == 4) return this.$emit('change',false);
            this.pageIndex++;
        },
        skipHandler(){
            this.$refs['img'][0].style.transition = "all 0s";
            this.$refs['content'][0].style.transition = "all 0s";
            this.pageIndex = 4
        }
    },
    computed: {
        ...mapGetters(['wdWidth']),
        imgList(){
            if( this.wdWidth > 900){
                return [
                    require('@/assets/introduction/1.png'),
                    require('@/assets/introduction/2.png'),
                    require('@/assets/introduction/3.png'),
                    require('@/assets/introduction/4.png'),
                    require('@/assets/introduction/5.png'),

                ]
            }else{
                return [
                    require('@/assets/introduction/m1.png'),
                    require('@/assets/introduction/m2.png'),
                    require('@/assets/introduction/m3.png'),
                    require('@/assets/introduction/m4.png'),
                    require('@/assets/introduction/m5.png'),

                ] 
            }
        },
        popStyle(){
            return { 
                transform:`scale(${this.showFlag? 1:0})`,
            }
        },
        maskStyle(){
            return {
                pointerEvents: this.showFlag?'auto':'none',
                visibility: this.showFlag?'visible':'hidden'
            }
        }
    },
}
</script>

<style lang="scss" scoped >
    .pop{
        width: 47rem;
        min-height: 34.5rem;
        border-radius: 1.06rem;
        background: #030303;
        transition: all .3s;
        &_mask{
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 1000000;
            background: rgba(0,0,0,.5);
            backdrop-filter: blur(4px);
        }
        &_img{
            width: inherit;
            background: #030303;
            object-fit: cover;
            transition: all .3s;
            &_wrapper{
                width: 100%;
                aspect-ratio: 47rem / 21.75rem;
                flex-shrink: 0;
                border-top-left-radius: 1.06rem;
                border-top-right-radius: 1.06rem;
                overflow: hidden;
            }
        }
        &_content{
            display: block;
            text-align: left;
            width: inherit;
            color: #FFFFFF;
            font-size: 1.5rem;
            line-height: 2.19rem;
            letter-spacing: .03rem;
            font-family: SVN-GilroyHeavy;
            margin-bottom: 1.88rem;
            flex-shrink: 0;
            transition: all .3s;
            &_wrapper{
                width: 100%;
                overflow: hidden;
                flex-wrap: nowrap;
            }
        }
        &_bottom{
            width: 100%;
            border-bottom-left-radius: 1.06rem;
            border-bottom-right-radius: 1.06rem;
            box-sizing: border-box;
            padding: 2.5rem{
                top:2rem;
            }
        }
        &_page{
            color: rgba(255,255,255,.54);
            font-size: 1.13rem;
            line-height: 1.56rem;
            letter-spacing: .03rem;
            font-family: PingFangSC-Regular;
        }
        &_btn{
            font-size: 1.13rem;
            line-height: 1.56rem;
            letter-spacing: .03rem;
            font-family: PingFangSC-Regular;
            min-width: 8.75rem;
            padding: .69rem 0;
            line-height: 2.94rem;
            border-radius: .5rem;
            cursor: pointer;
            &:nth-of-type(1){
                color: #FE5900;
                box-sizing: border-box;
                border: .09rem solid #FE5900;
            }
            &:nth-of-type(2){
                color: #FFFFFF;
                margin-left: 1.25rem;
                background: linear-gradient(90deg, #FE5900 0%, #C51A24 100%);
            }
        }
    }

@media screen and (max-width: 900px) {
    .pop{
        width: calc( 100vw - 5rem );
        &_btn{
            border-radius: 1rem;
        }
    }
}
</style>