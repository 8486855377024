<template>
  <div class="bg flex flex-jc flex-ac" :style="[bg]">
    <div class="timePop">
      <div class="logo"></div>
      <div class="timePop_text1">Sign in successful</div>
      <div class="timePop_text2">
        Congratulations on earning <br />
        <span>{{ signinfo.sign_in_reward }}</span> TAICE!
      </div>
      <div class="timePop_text3" v-if="signinfo.surplus_sign_in_days > 0">
        Sign in for another {{ signinfo.surplus_sign_in_days }} days and earn an
        <br />
        additional <span>{{ signinfo.seven_days_sign_in_reward }}</span> TAICE!
      </div>
      <div class="timePop_btn" @click="colse">OK</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { nextTick } from 'vue'
export default {
  name: 'timePop',
  props: {},
  data() {
    return {
      time: 3,
      timeinterval: null,
    }
  },
  mounted() {},
  methods: {
    colse() {
      nextTick(() => {
        this.$store.commit('user/SET_SHOWSIGN', false)
      })
    },
  },
  computed: {
    ...mapGetters(['SPK', 'uid', 'showsign', 'signinfo']),
    bg() {
      return {
        backdropFilter: `blur(${this.showsign ? 7 : 0}px)`,
        pointerEvents: this.showsign ? 'auto' : 'none',
        opacity: this.showsign ? 1 : 0,
        zIndex: this.showsign ? 990 : -100,
      }
    },
  },
  watch: {
    showsign(nval) {
      console.log(nval)
    },
  },
}
</script>

<style lang="scss" scoped>
.timePop {
  max-width: 40.31rem;
  width: 100%;
  // height: 28.13rem;
  box-sizing: border-box;
  margin: 0 3rem;
  background: linear-gradient(
    180deg,
    rgba(254, 89, 0, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  border-radius: 2.25rem;
  border: 0.09rem solid rgba(130, 130, 130, 0.31);
  background-clip: padding-box;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  &_text1 {
    font-family: SVN-GilroyBold, SVN;
    font-size: 2.4rem;
    color: #ffffff;
    line-height: 2.19rem;
    font-weight: 600;
  }
  &_text2 {
    font-family: SVN-GilroyMedium;
    font-size: 2rem;
    color: #ffffff;
    line-height: 2.75rem;
    margin: 1.5rem 0 1.63rem;
    font-weight: 300;
  }
  &_text3 {
    margin: 0 0 1.63rem;
    font-family: SVN-GilroyMedium;
    font-size: 1.5rem;
    color: #ffffff;
    line-height: 2.13rem;
  }
  span {
    color: #ffb700;
  }
  &_Currency {
    span:nth-child(1) {
      font-family: SVN-GilroyMedium;
      font-size: 3.75rem;
      color: #ffdc00;
      line-height: 4.75rem;
      font-weight: 700;
    }
    span:nth-child(2) {
      font-family: SVN-GilroyMedium;
      font-size: 1.38rem;
      color: #ffdc00;
      line-height: 1.81rem;
    }
  }
  &_btn {
    margin-bottom: 3.25rem;
    font-family: SVN-GilroyMedium;
    width: 21.81rem;
    height: 3.88rem;
    background: #fc5701;
    border-radius: 1rem;
    border: 0.09rem solid rgba(0, 0, 0, 0.31);
    text-align: center;
    line-height: 3.88rem;
    font-weight: 600;
    font-size: 1.8rem;
    color: #ffffff;
  }
}
.logo {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto -4.9rem;
  top: -4.9rem;
  width: 13.25rem;
  height: 10.56rem;
  background: url(@/assets/timePop_logo.png) top/0 no-repeat;
  background-size: 100% 100%;
}
.bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  opacity: 0;
  transition: all 0.35s;
}
</style>
