import Vue from 'vue';
import walletSign from './index.vue'

const walletSignBox = Vue.extend(walletSign);

walletSignBox.install = function(data){
    let instance = new walletSignBox({
        data
    }).$mount()

    document.body.appendChild(instance.$el);
    return instance.show();
}

export default walletSignBox ;