import {
  configureChains,
  createConfig,
  getAccount,
  signMessage,
  getWalletClient,
  waitForTransaction,
  prepareWriteContract,
  writeContract,
  sendTransaction,
  fetchFeeData,
  fetchBalance,
  watchAccount,
  watchNetwork,
  disconnect,
} from '@wagmi/core'
import { bscTestnet, mainnet, localhost, bsc } from '@wagmi/core/chains'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'
import { Message } from 'element-ui'
import store from '@/store/index'
import usdtABI from '@/lib/js/usdtABI.json'
import spkABI from '@/lib/js/spkABI.json'
import nftABI from '@/lib/js/nftABI.json'
import walletLog from '@/components/walletLog/index.js'
import { ethers, providers, utils } from 'ethers'

const projectId = '69ce5744fda5846ac8cf65556be3163d'

const chains = []

switch (process.env.NODE_ENV) {
  case 'development':
    chains.push(bscTestnet)
    break
  case 'test':
    chains.push(bscTestnet)
    break
  case 'production':
    chains.push(bsc)
    break
}

export class WalletEthers {
  static message =
    'Argee to signing message on AICEAN. This request will not trigger a blockchain transaction or cost any     fees.'
  static COIN_TYPE = {
    BNB: 'BNB',
    SPK: process.env.VUE_APP_SPK_ADDRESS,
    USDT: process.env.VUE_APP_UTSD_ADDRESS,
  }
  static modal = null
  static #account = null
  static #connectors = null
  static reload = () => {}

  constructor(reload = () => {}) {
    if (!WalletEthers.modal) {
      this.init()
      WalletEthers.reload = reload
    }
  }

  async init() {
    try {
      const { publicClient } = configureChains(chains, [
        w3mProvider({ projectId }),
      ])
      WalletEthers.#connectors = w3mConnectors({
        projectId,
        chains,
        version: 1,
      })
      const wagmiConfig = createConfig({
        autoConnect: true,
        connectors: WalletEthers.#connectors,
        publicClient,
      })

      const ethereumClient = new EthereumClient(wagmiConfig, chains)
      WalletEthers.modal = new Web3Modal(
        {
          projectId,
          themeVariables: { '--w3m-z-index': 1050 },
          explorerRecommendedWalletIds: [
            '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662', //bitget
            '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709', // okx
          ],
        },
        ethereumClient
      )
      // WalletEthers.modal.setDefaultChain(bsc);

      console.log('测试----', WalletEthers.#connectors[0])
      watchAccount(async (account) => {
        console.log('测试-----', account)
        if (store.state.user.token && account.address) {
          WalletEthers.#account = account.address
          if (
            !(
              store.state.user.Info?.email &&
              !store.state.user.Info?.chain_address
            ) &&
            store.state.user.Info?.chain_address !==
              WalletEthers.#account?.toLowerCase()
          ) {
            Message({
              message: 'chainAddress mismatch userAddress',
              type: 'error',
              showClose: true,
              customClass: 'myMessage',
            })
            return store.dispatch('user/logout')
          }
        } else if (
          !store.state.user.token &&
          store.state.common.showLog &&
          account.address
        ) {
          // try{
          //     this.setAccounts()
          //     let signature = await this.sign(WalletEthers.message);
          //     console.log('invite_code----',store.state.common.inviteCode)
          //     await store.dispatch('user/login',{type: 1, data: { chain_address:WalletEthers.#account,signature,message:WalletEthers.message,invite_code:store.state.common.inviteCode }})
          //     store.commit('common/LOG_STATE',false)
          //     Message({
          //         message:'Login Success',
          //         type:'success',
          //         showClose:true,
          //         customClass:'myMessage'
          //     })
          //     WalletEthers.#reload()
          // }catch(err){
          //     console.log('捕获到错误---',err)
          //     await disconnect();
          //     store.dispatch('user/logout');
          // }
        }
      })
      watchNetwork((obj) => {
        // this.checkNet(obj.chains[0].id);
      })
    } catch (err) {
      Message({
        message: err,
        type: 'error',
        showClose: true,
        customClass: 'myMessage',
      })
    }
  }

  hasInType(type) {
    return WalletEthers.COIN_TYPE[type]
  }

  checkNet(id) {
    if (process.env.NODE_ENV == 'production' && id != 56)
      return Message({
        message: 'Network Mismatch',
        type: 'warning',
        showClose: true,
        customClass: 'myMessage',
      })
    else if (process.env.NODE_ENV == 'development' && id != 1337)
      return Message({
        message: 'dev Network Mismatch',
        type: 'warning',
        showClose: true,
        customClass: 'myMessage',
      })
    else if (process.env.NODE_ENV == 'test' && id != 97)
      return Message({
        message: 'test Network Mismatch',
        type: 'warning',
        showClose: true,
        customClass: 'myMessage',
      })
  }

  setAccounts() {
    const { address, isConnected } = getAccount()
    if (!isConnected) throw 'Please Connect Wallet'
    else WalletEthers.#account = address
    console.log('拿到账户----', WalletEthers.#account)
    return WalletEthers.#account
  }

  getAccount() {
    if (!WalletEthers.#account) return this.setAccounts()
    return WalletEthers.#account
  }

  getMessage() {
    return WalletEthers.message
  }

  async sign(isBind = false) {
    console.log(store.state.user.Info)
    const { address, isConnected } = getAccount()
    if (!isConnected) throw 'Please Connect Wallet'
    if (!address && !isBind) {
      walletLog.install({})
      throw { message: 'chainAddress not null' }
    } else if (
      store.state.user.token &&
      store.state.user.Info.chain_address !== address?.toLowerCase() &&
      !isBind
    ) {
      throw { message: 'chainAddress mismatch userAddress' }
    }
    try {
      let signature = await signMessage({ message: WalletEthers.message })
      return signature
    } catch (err) {
      console.log('捕获到错误---', err)
      Message({
        message: err?.reason || err?.data?.message || err?.message || err,
        type: 'error',
        showClose: true,
        customClass: 'myMessage',
      })
      throw err
    }
  }

  async getWallet(type) {
    console.log('判定地址------', !WalletEthers.#account)
    if (!WalletEthers.#account) {
      walletLog.install({})
      throw { message: 'chainAddress not null' }
    }
    if (
      store.state.user.Info.chain_address !==
      WalletEthers.#account.toLowerCase()
    ) {
      Message({
        message: 'chainAddress mismatch userAddress',
        type: 'error',
        showClose: true,
        customClass: 'myMessage',
      })
      throw { message: 'chainAddress mismatch userAddress' }
    }
    if (!this.hasInType(type)) {
      Message({
        message: 'error type',
        type: 'error',
        showClose: true,
        customClass: 'myMessage',
      })
      throw { message: 'error type' }
    }
    try {
      if (WalletEthers.COIN_TYPE.BNB == WalletEthers.COIN_TYPE[type]) {
        console.log('拿bnb余额----')
        const { value, decimals } = await fetchBalance({
          address: WalletEthers.#account,
        })
        console.log('这是格式？--', decimals)
        return ethers.utils.formatUnits(value, decimals)
      } else {
        const { value, decimals } = await fetchBalance({
          address: WalletEthers.#account,
          token: WalletEthers.COIN_TYPE[type],
        })
        return ethers.utils.formatUnits(value, decimals)
      }
    } catch (err) {
      console.dir(err)
      Message({
        message: err?.reason || err?.data?.message || err?.message || err,
        type: 'error',
        showClose: true,
        customClass: 'myMessage',
      })
    }
  }

  async transfer(type, address, amount) {
    console.log(
      type,
      address,
      amount,
      ethers.utils.parseUnits(String(amount), 18),
      WalletEthers.COIN_TYPE[type]
    )
    try {
      if (!WalletEthers.#account) {
        walletLog.install({})
        throw { message: 'chainAddress not null' }
      } else if (
        store.state.user.Info.chain_address !==
        WalletEthers.#account.toLowerCase()
      ) {
        throw { message: 'chainAddress mismatch userAddress' }
      }
      let value = await this.getWallet(type)
      if (Number(value) < Number(amount))
        return Message({
          message: 'transfer value greater than wallet value',
          type: 'warning',
          showClose: true,
          customClass: 'myMessage',
        })
      if (WalletEthers.COIN_TYPE.BNB == WalletEthers.COIN_TYPE[type]) {
        const {
          gasPrice,
          formatted: { gasPrice: formatGasPrice },
        } = await fetchFeeData()
        let tx = await sendTransaction({
          maxFeePerGas: 21000,
          gasPrice: gasPrice,
          to: address,
          value: ethers.utils.parseEther(amount.toString()),
        })
        console.log('----', tx)
      } else {
        let payAmount = ethers.utils.parseUnits(String(amount), 18)
        const { account } = await getWalletClient()
        const contractConfig = {
          address: WalletEthers.COIN_TYPE[type],
          abi: type == 'SPK' ? spkABI : usdtABI,
          account,
        }
        const { request } = await prepareWriteContract({
          ...contractConfig,
          functionName: 'approve',
          args: [WalletEthers.#account, payAmount],
        })
        const { hash } = await writeContract(request)
        const data = await waitForTransaction({ hash })
        if (data.status == 'success') {
          const { request: transferRequest } = await prepareWriteContract({
            ...contractConfig,
            functionName: 'transferFrom',
            args: [WalletEthers.#account, address, payAmount],
          })
          return await writeContract(transferRequest)
        }
        throw data
      }
    } catch (err) {
      Message({
        message: err?.reason || err?.data?.message || err?.message || err,
        type: 'error',
        showClose: true,
        customClass: 'myMessage',
      })
      throw err
    }
  }

  async nftTransfer(id, address) {
    try {
      if (!WalletEthers.#account) {
        walletLog.install({})
        throw { message: 'chainAddress not null' }
      } else if (
        store.state.user.Info.chain_address !==
        WalletEthers.#account.toLowerCase()
      ) {
        throw { message: 'chainAddress mismatch userAddress' }
      }
      const { account } = await getWalletClient()
      const contractConfig = {
        address: process.env.VUE_APP_SNFT_ADDRESS,
        abi: nftABI,
        account,
      }
      console.log('这是配置----', contractConfig)
      const { request } = await prepareWriteContract({
        ...contractConfig,
        functionName: 'approve',
        args: [address, id],
      })
      const { hash } = await writeContract(request)
      const data = await waitForTransaction({ hash })
      if (data.status == 'success') {
        const { request: transferRequest } = await prepareWriteContract({
          ...contractConfig,
          functionName: 'transferFrom',
          args: [WalletEthers.#account, address, id],
        })
        return await writeContract(transferRequest)
      }
      throw data
    } catch (err) {
      console.dir(err)
      Message({
        message: err?.reason || err?.data?.message || err?.message || err,
        type: 'error',
        showClose: true,
        customClass: 'myMessage',
      })
      throw err
    }
  }

  async destory() {
    WalletEthers.#account = null
  }
}
