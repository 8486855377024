import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './api'
import Nprogress from 'nprogress'
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n'
import 'nprogress/nprogress.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { init } from '@/utils/common'
import * as echarts from 'echarts'
import '../index.scss'
import '@/lib/css/ttf.css'
import '@/lib/css/flex.scss'
import '@/lib/css/skeleton.css'
import drag from '@/lib/js/drag';
import Confirm from '@/components/confirm/index';

Vue.config.productionTip = false


Vue.prototype.$myConfirm = Confirm.install

Vue.prototype.$store=store
Vue.prototype.$nprogress=Nprogress
Vue.prototype.$request=request
Vue.prototype.$echarts=echarts

Vue.directive('drag',drag)

Vue.use(ElementUI)
.use(VueClipboard)

init()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus=this
  }
}).$mount('#app')
