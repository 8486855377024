<template>
  <header
    class="flex flex-dir flex-ac flex-jsb"
    ref="header"
    @click="changeHeight"
    :style="{ width: `${wdWidth}px`, height: shrinkHeight }"
  >
    <div class="flex flex-jc flex-ac">
      <img
        v-show="wdWidth <= 900"
        class="hamburg"
        src="@/assets/Hamburg.svg"
        @click.stop="showFlag = !showFlag"
      />
      <div class="logo_wrapper flex flex-jc flex-ac">
        <img class="logo" src="@/assets/logo.png" @click="backHandler" />
        <div class="beta flex flex-jc flex-ac" @click.stop="jumpBeta">Beta</div>
      </div>
      <ul v-show="wdWidth > 900" class="tag flex flex-jc flex-ac">
        <li
          class="tag_item"
          :style="{
            color: activeIndex == tIndex ? '#FE5900' : 'var(--gray)',
            fontFamily:
              activeIndex == tIndex ? 'SVN-GilroyBold' : 'SVN-GilroyMedium',
            fontSize: wdWidth > 2560 ? '1rem' : '1rem',
          }"
          v-for="(tag, tIndex) in tagList.slice(0, 7)"
          :key="tIndex"
          @click.stop="changeTag(tIndex)"
        >
          {{ tag }}
        </li>
        <!-- <li class="beta tag_item flex flex-jc flex-ac" @click.stop="jumpBeta" >Beta</li> -->
      </ul>
    </div>

    <div class="flex flex-jc flex-jfe flex-ac" style="flex: 1">
      <div class="top_search flex flex-ac" style="flex: 1">
        <img class="top_search_icon" src="@/assets/search.svg" />
        <input
          ref="search"
          class="top_search_input"
          type="text"
          v-model="keyword"
          placeholder="Search..."
        />
        <el-tooltip content="Search">
          <div
            class="top_search_btn flex flex-jc flex-ac"
            @click="toList()"
          ></div>
        </el-tooltip>
      </div>
      <div
        v-if="token && wdWidth <= 900"
        class="uploadicon_search"
        @click="tosearch"
      >
        <img src="@/assets/search.png" alt="" />
      </div>
      <div v-if="token" class="uploadicon" @click="touploadvideo">
        <img src="@/assets/uploding.png" alt="" />
      </div>
      <div v-if="token" class="right flex flex-jc flex-ac">
        <template v-if="wdWidth <= 900">
          <img
            class="btn_avatar"
            @click.stop="btnHandler"
            @mouseenter="btnHandler"
            @mouseleave="btnHandler"
            :src="avatar || require('@/assets/default-avatar.png')"
          />

          <!-- <span
            class="btn_name"
            @click.stop="btnHandler"
            @mouseenter="btnHandler"
            @mouseleave="btnHandler"
            >{{ name }}</span
          > -->
          <div class="flex flex-jc flex-ac" @click.stop="btnHandler">
            <img
              class="right_info_arrow"
              src="@/assets/down.svg"
              :style="{ transform: `rotate(${showPop ? 0 : -90}deg)` }"
            />
            <div
              class="btn_pop flex flex-col flex-jfs flex-ac"
              :style="{
                height: `${showPop ? 20 : 0}rem`,
                border: `${
                  showPop ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
                }`,
                pointEvents: showPop ? 'auto' : 'none',
              }"
            >
              <div
                class="btn_pop_item flex flex-jc flex-ac"
                v-for="(pItem, pIndex) in popList"
                :key="pIndex"
                @click.stop="jumpRouter(pItem.router)"
              >
                {{ pItem.title }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="right_wallet flex flex-jc flex-ac"
            @click.stop="jumpHistory"
          >
            <img class="right_wallet_icon" src="@/assets/wallet.svg" />
            <span class="right_wallet_txt"
              >{{ parseInt(Number(SPK) * 100) / 100 }} TAICE</span
            >
          </div>
          <template>
            <img
              class="right_avatar"
              :src="avatar || require('@/assets/default-avatar.png')"
              @click.stop="btnHandler"
              @mouseenter="btnHandler(1)"
            />
            <div
              class="btn_pop flex flex-col"
              @mouseleave="btnHandler(2)"
              :style="{
                height: `${showPop ? 24 : 0}rem`,
                border: `${
                  showPop ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
                }`,
                pointEvents: showPop ? 'auto' : 'none',
              }"
            >
              <div class="btn_pop_user">
                <div class="btn_pop_user_img">
                  <img
                    :src="avatar || require('@/assets/default-avatar.png')"
                    alt=""
                  />
                </div>
                <div class="btn_pop_user_info">
                  <div class="text1">{{ name }}</div>
                  <div class="text2">{{ chain | chainFilter }}</div>
                </div>
              </div>
              <div
                class="btn_pop_item flex flex-ac"
                v-for="(pItem, pIndex) in popList"
                :key="pIndex"
                @click.stop="jumpRouter(pItem.router)"
              >
                <img class="btn_pop_item_icon" :src="pItem.url" alt="" />
                {{ pItem.title }}
              </div>
            </div>
          </template>

          <!-- <div
            class="right_info flex flex-jc flex-ac"
            @click.stop="btnHandler"
            @mouseenter="btnHandler(1)"
            @mouseleave="btnHandler(2)"
          >
            <span class="right_info_name flex flex-jc flex-ac">{{ name }}</span>
            <span class="right_info_chain">{{ chain | chainFilter }}</span>
            <img
              class="right_info_arrow"
              src="@/assets/down.svg"
              :style="{ transform: `rotate(${showPop ? 0 : -90}deg)` }"
            />
            <div
              class="btn_pop flex flex-col"
              :style="{
                height: `${showPop ? 24 : 0}rem`,
                border: `${
                  showPop ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
                }`,
                pointEvents: showPop ? 'auto' : 'none',
              }"
            >
              <div class="btn_pop_user">
                <div class="btn_pop_user_img">
                  <img
                    :src="avatar || require('@/assets/default-avatar.png')"
                    alt=""
                  />
                </div>
                <div class="btn_pop_user_info">
                  <div class="text1">{{ name }}</div>
                  <div class="text2">{{ chain | chainFilter }}</div>
                </div>
              </div>
              <div
                class="btn_pop_item flex flex-ac"
                v-for="(pItem, pIndex) in popList"
                :key="pIndex"
                @click.stop="jumpRouter(pItem.router)"
              >
                <img class="btn_pop_item_icon" :src="pItem.url" alt="" />
                {{ pItem.title }}
              </div>
            </div>
          </div> -->
        </template>
      </div>
      <div v-else class="btn flex flex-jc flex-ac" @click.stop="btnHandler">
        <!-- <template v-if="wdWidth <= 900" >
                <img class="btn_avatar" :src="avatar || require('@/assets/default-avatar.png') " />
                <span class="btn_name" >{{name}}</span>
            </template> -->
        <span class="btn_txt">Login</span>
      </div>
    </div>
    <ul
      class="tagpop flex flex-col flex-jfs flex-ac"
      :style="{ maxHeight: showFlag && wdWidth <= 900 ? '70vh' : 0 }"
      @click.stop
    >
      <li
        class="tagpop_item"
        :style="{
          background: activeIndex == tIndex ? '#FE5900' : 'transparent',
        }"
        v-for="(tag, tIndex) in tagList"
        :key="tIndex"
        @click.stop="changeTag(tIndex)"
      >
        {{ tag }}
      </li>
    </ul>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { WalletEthers } from '@/utils/walletconnect2'
import { getAccount, watchAccount, disconnect } from '@wagmi/core'
import walletSign from '@/components/walletSign/index.js'

export default {
  inject: ['taskRefresh'],
  data() {
    return {
      keyword: '',
      tagList: [
        'Home',
        'Discover',
        'Task',
        // 'Creators',
        'Leaderboard',
        'Referral',
        'Campaign',
      ],
      activeIndex: 0,
      showFlag: false,
      lang: 'en',
      langList: [
        { icon: '🇨🇳', lang: 'zh' },
        { icon: '🇺🇸', lang: 'en' },
      ],
      // popList: ['Profile', 'Wallet', 'Referral', 'Logout'],
      popList: [
        {
          title: 'Profile',
          router: 'Profile',
          url: require('@/assets/profile.svg'),
        },
        {
          title: 'My Videos',
          router: 'creation',
          url: require('@/assets/videos.svg'),
        },
        {
          title: 'My Collections',
          router: 'Collect',
          url: require('@/assets/collections.svg'),
        },
        {
          title: 'Performance',
          router: 'Analyze',
          url: require('@/assets/performance.svg'),
        },
        {
          title: 'Setting',
          router: 'ProfileEdit',
          url: require('@/assets/setting.svg'),
        },
        {
          title: 'Logout',
          router: 'Logout',
          url: require('@/assets/logout.svg'),
        },
      ],
      showPop: false,
      popCount: 5,
      unWatch: null,
    }
  },
  filters: {
    chainFilter(val) {
      return val.slice(0, 6) + '...' + val.slice(val.length - 6, val.length - 1)
    },
  },

  mounted() {
    // this.$emoji(this.$refs['us'])
    window.addEventListener('scroll', this.scrollHanlder)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHanlder)
  },
  methods: {
    ...mapActions('user', ['logout']),
    toList() {
      this.$router.replace({
        name: 'Discover',
        query: {
          keyword: this.keyword,
        },
      })
      this.keyword = ''
    },
    async test() {
      this.walletObj.getAccount()
      // this.$router.push({name:'Analyze'})
      // console.log('----test---')
      // this.taskRefresh()
    },
    touploadvideo() {
      this.$router.push({ name: 'Youtube' })
    },
    tosearch() {
      this.$router.push({ name: 'Discover' })
    },
    changeTag(index) {
      if ([2, 4].includes(index) && !this.token) {
        // this.$message({
        //   message: 'Please Login',
        //   type: 'error',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
        this.btnHandler()
        return
      }

      if (this.showPop) this.showPop = false
      if (
        this.tagList[this.activeIndex] == this.$route.name &&
        this.activeIndex == index
      )
        return
      this.activeIndex = index
      if (this.isMoblie && this.showFlag) this.showFlag = false
      this.$store.commit('common/CLEAR_KEEP_ROUTES')
      this.$router.push({ name: this.tagList[index] })
    },

    btnHandler(is) {
      if (!this.$store.state.user.token)
        return this.$store.commit('common/LOG_STATE', true)
      if (is === 1) {
        this.showPop = true
      } else if (is === 2) {
        this.showPop = false
      } else {
        this.showPop = !this.showPop
      }

      // this.activeIndex=-1
    },
    checkWallet() {
      return {
        width: this.token ? 'auto' : 0,
        minWidth: this.token ? '8rem' : 0,
        padding: this.token ? '.94rem 1.88rem ' : '.94rem 0',
      }
    },
    changeHeight() {
      if (this.showPop) this.showPop = false
      if (!this.isMoblie) return
      this.showFlag = false
    },
    ChangeLang(lang) {
      this.lang = lang
    },
    jumpRouter(name) {
      if (name == 'Logout') {
        this.showPop = false
        return this.logout()
      }
      if (name == 'creation') {
        this.$router.replace({ path: `/SelfProfile/creation` })
        return
      }
      console.log('测试——', this.$route.name)
      if (
        (name != 'Profile' && name == this.$route.name) ||
        (name == 'Profile' && this.$route.name == 'SelfProfile')
      )
        return (this.showPop = false)
      this.showPop = false
      if (name == 'Profile') return this.$router.push({ name: 'SelfProfile' })
      this.$router.push({ name: name })
    },
    jumpBeta() {
      if ('Beta' == this.$route.name) return
      if (this.showPop) this.showPop = false
      if (this.showFlag && this.isMoblie) this.showFlag = false
      console.log('这是下标---', this.activeIndex)
      this.activeIndex = 5
      console.log('这是下标---', this.activeIndex)
      this.$forceUpdate()
      this.$store.commit('common/CLEAR_KEEP_ROUTES')
      this.$router.push({ name: 'Beta' })
    },
    async bindHandler() {
      try {
        const { isConnected } = getAccount()
        if (!isConnected) {
          await WalletEthers.modal.openModal()
          if (!this.unWatch) {
            this.unWatch = watchAccount(async (account) => {
              if (account.address && account.isConnected) walletSign.install({})
            })
          }
        } else walletSign.install({})
      } catch (err) {
        console.log('err', err)
        this.$message({
          message:
            err?.reason ||
            err?.data?.message ||
            err?.message ||
            err?.msb ||
            err,
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
        if (this.unWatch) {
          this.unWatch()
          this.unWatch = null
        }
        await disconnect()
      }
    },
    jumpHistory() {
      // this.$router.push({ name: 'pointHistory' })
      this.$router.push({ name: 'ExchangeHistory' })
      return
      if (!this.chain && this.popCount) {
        this.bindHandler()
        this.popCount--
      } else if (this.chain) {
        this.$router.push({ name: 'pointHistory' })
      }
    },
    scrollHanlder(e) {
      // console.log('滚动----');
      this.$refs['header'].style.background = `rgba(0,0,0,${
        window.scrollY / (window.innerHeight * 0.15)
      })`
    },
    backHandler() {
      // if (!this.token) {
      //   this.$router.replace({ name: 'Started' })
      //   return
      // }
      if (this.$route.name === 'Home') this.$router.go(0)
      let backlen = history.length
      window.history.go(-backlen)
      this.$router.replace({ name: 'Home' })
    },
  },
  computed: {
    ...mapGetters([
      'wdWidth',
      'isMoblie',
      'token',
      'avatar',
      'name',
      'chain',
      'SPK',
      'walletObj',
    ]),
    shrinkHeight() {
      return this.wdWidth <= 900 ? '6.38rem' : '5.81rem'
    },
  },
  watch: {
    wdWidth: {
      handler(val) {
        console.log('发生变化')
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@include fade(0.2);
.top {
  &_search {
    margin: 0 5rem 0 4rem;
    transform: skew(-9deg);
    background: rgba(255, 255, 255, 0.12);
    border-radius: 0.38rem;
    height: 3.44rem;
    position: relative;

    &_wrapper {
      width: 100%;
      margin-top: 1.56rem;
      box-sizing: border-box;
      padding: 0 13.75rem;
    }
    & > * {
      transform: skew(9deg);
    }

    &_icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 1rem;
      margin-right: 0.63rem;
    }

    &_input {
      font-size: 1.25rem;
      line-height: 1.56rem;
      flex-grow: 0.85;
      font-family: SVN-Gilroy;
      background: transparent;
      box-shadow: none;
      border: none;
      color: #ffffff;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: rgba(255, 255, 255, 0.2);
      }
    }

    &_btn {
      position: absolute;
      right: -1.44rem;
      z-index: 100;
      height: 2.69rem;
      width: 3rem;
      border-radius: 0.38rem;
      transform: skew(-9deg) !important;
      background: linear-gradient(to right, #fe5900, #c51a24);
      border: 0.15rem solid rgba(0, 0, 0, 0.31);
      cursor: pointer;
      &::after {
        content: '';
        transform: skew(18deg) !important;
        background: url(@/assets/rightarrow.png) no-repeat center/100% 100%;
        display: block;
        width: 1.44rem;
        height: 1.44rem;
      }
    }
  }
}
header {
  position: fixed;
  top: 0;
  z-index: 999;
  margin: auto;
  padding: 0 5.75rem;
  box-sizing: border-box;
  border-bottom: 0.06rem solid rgba(255, 255, 255, 0.1);
  // overflow: hidden;
  transition: height 0.3s;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(4px);
}

i {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  // transition: background .5s;
}

.logo {
  width: 9.13rem;
  height: 2rem;
  cursor: pointer;

  &_wrapper {
    margin-right: 4.6rem;
  }
}

.btn {
  transform: skew(-9deg);
  background: linear-gradient(to right, #fe5900, #c51a24);
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.31);
  // min-width:12.31rem;
  // height:3.31rem;
  height: 2.75rem;
  min-width: 10.38rem;
  animation: fade 1s ease;
  position: relative;
  // transition: box-shadow .3s;
  transition: all 0.3s;
  cursor: pointer;

  &_wrapper {
    width: 12.31rem;
  }

  &_txt {
    color: #ffffff;
    font-size: 1.13rem;
    line-height: 1.44rem;
    font-family: SVN-GilroySemiBold;
    letter-spacing: 0.05rem;
    width: 12.31rem;
  }

  &_wallet {
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    transform: skew(0deg) !important;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    // width:0;
    min-width: 0;

    &_txt {
      font-size: 1.13rem;
      line-height: 1.44rem;
      color: #ffffff;
      transform: skew(9deg) !important;
      display: block;
    }
  }

  &_avatar {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    margin-right: 0.63rem;
    margin-left: 0.64rem;
    background-color: rgba(0, 0, 0, 0.5);
    object-fit: cover;
  }

  &_name {
    font-size: 1rem;
    line-height: 1.25rem;
    font-family: SVN-GilroyMedium;
    color: #ffffff;
  }

  &_chain {
    font-size: 0.88rem;
    line-height: 1.13rem;
    color: #ffffff;
    font-family: SVN-Gilroy;
  }

  &_arrow {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.69rem;
    margin-right: 0.34rem;
    transition: all 0.3s;
  }

  &:hover {
    box-shadow: rgba(254, 89, 0, 0.4) 0px 0px 20px;
  }

  &_pop {
    position: absolute;
    padding: 0 1.25rem;
    // z-index:1;
    background-color: #0f0f0f;
    border: 1px solid rgba(255, 255, 255, 0.1);
    top: 4.63rem;
    right: 1.94rem;
    height: 0rem;
    // border:1px solid red;
    width: 19.31rem;
    overflow: hidden;
    transition: height 0.3s;
    backdrop-filter: blur(14px);
    border-radius: 0.38rem;
    &_user {
      height: 5rem;
      display: flex;
      align-items: center;
      border-bottom: 0.06rem solid #ffffff10;

      &_img {
        width: 2.5rem;
        height: 2.5rem;
        border: 0.05rem solid rgba(255, 255, 255, 0.56);
        border-radius: 50%;
        overflow: hidden;
        margin-right: 0.63rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &_info {
        font-family: SVN-GilroyMedium;
        color: #ffffff;
        text-align: left;
        .text1 {
          font-size: 1rem;
        }

        .text2 {
          font-size: 0.88rem;
          text-align: left;
        }
      }
    }

    &_item {
      cursor: pointer;
      color: #ffffff;
      font-size: 0.88rem;
      line-height: 1.13rem;
      letter-spacing: 0.02rem;
      font-family: SVN-GilroyMedium;
      padding: 0.94rem 0;
      width: 9.94rem;
      &_icon {
        width: 1.13rem;
        height: 1.13rem;
        margin-right: 0.63rem;
      }
      &:nth-of-type(1) {
        margin-top: 0.8rem;
      }

      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }
  }
}

.btn > * {
  transform: skew(9deg);
}

.hamburg {
  height: 2rem;
  width: 2rem;
  margin-right: 1.25rem;
}

.tag {
  list-style: none;

  // margin-top:1rem;
  &_item {
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0.04rem;
    // margin-bottom:.5rem;
    font-family: SVN-GilroyBold;
    cursor: pointer;

    &:nth-of-type(n + 2) {
      margin-left: 2.75rem;
    }
  }
}

.channels {
  height: 2.2rem;
  width: 2.2rem;
  object-fit: contain;
  margin: 0 0.5rem;
  cursor: pointer;
}

.link {
  color: var(--gray);
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0.8rem 0;
  white-space: nowrap;
  font-family: SVN-Gilroy, SVN;
  display: inline-block;
  text-decoration: none;
}

.beta {
  background: no-repeat center/100% url(@/assets/beta.svg);
  width: 4.19rem;
  height: 1.88rem;
  // width: 3.13rem;
  // height: 1.38rem;
  color: #ffffff;
  font-family: SVN-GilroySemiBold;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.04rem;
  // font-size: 0.73rem;
  // line-height: 0.88rem;
  // letter-spacing: 0.03rem;
  box-sizing: border-box;
  padding-left: 0.4rem;
  margin-left: 0.4rem;
  cursor: pointer;
  animation-name: scaleDraw;
  /*关键帧名称*/
  animation-timing-function: ease-in-out;
  /*动画的速度曲线*/
  animation-iteration-count: infinite;
  /*动画播放的次数*/
  animation-duration: 3s;
}

@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1);
    /*开始为原始大小*/
  }

  25% {
    transform: scale(1.1);
    /*放大1.1倍*/
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }
}
.uploadicon {
  width: 2.4rem;
  height: 2.2rem;
  margin-right: 1rem;
  cursor: pointer;
  &_search {
    width: 2.7rem;
    height: 2.7rem;
    margin-right: 1.3rem;
    img {
      width: 2.7rem;
      height: 2.7rem;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.right {
  &_wallet {
    background: rgba(247, 105, 0, 0.2);
    border-radius: 1.19rem;
    padding: 0.5rem 1.28rem;
    white-space: nowrap;
    cursor: pointer;

    &_icon {
      height: 1.13rem;
      width: 1.13rem;
      margin-right: 0.31rem;
    }

    &_txt {
      color: white;
      font-size: 1rem;
      line-height: 1.25rem;
      font-family: SVN-GilroyMedium;
    }
  }

  &_avatar {
    height: 1.88rem;
    width: 1.88rem;
    border-radius: 100%;
    border: 0.04rem solid rgba(255, 255, 255, 0.56);
    box-sizing: border-box;
    margin: 0 0.75rem;
  }

  &_info {
    border-radius: 1.19rem;
    padding: 0.5rem 0.81rem 0.5rem 1.31rem;
    background: #272727;
    position: relative;
    cursor: pointer;

    &_name {
      color: white;
      font-size: 1rem;
      line-height: 1.25rem;
      display: block;
      font-family: SVN-GilroyMedium;
      position: relative;
      padding-right: 0.5rem;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 8rem;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 0.06rem;
        height: 1rem;
      }
    }

    &_chain {
      color: #fe5900;
      font-size: 0.88rem;
      line-height: 1.13rem;
      font-family: SVN-Gilroy;
      margin-right: 0.63rem;
    }

    &_arrow {
      height: 1rem;
      width: 1rem;
      transition: all 0.3s;
    }
  }
}

.tagpop {
  display: none;
}

@media screen and (max-width: 900px) {
  // header{
  //     padding:1.25rem 0;
  //     background:rgba(0,0,0,.8);
  //     backdrop-filter: blur(4px);
  // }
  // .logo{
  //     margin-right:0;
  // }
  // li{
  //     margin-left:0 !important;
  // }
  .top {
    &_search {
      display: none;
      margin-right: 10rem;
      height: 4rem;
      margin-top: 1.25rem;

      &_wrapper {
        width: 100%;
        box-sizing: border-box;
        padding: 0 2.5rem;
      }
    }
  }
  header {
    padding: 0 1.88rem;
  }

  .btn {
    // margin-top:1rem;
    &_wrapper {
      width: auto;
    }

    &_name {
      max-width: 8rem;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_name,
    &_chain {
      transform: scale(0.8);
    }

    &_wallet {
      &_txt {
        transform: scale(0.8) !important;
      }
    }

    &_pop {
      top: 4.63rem;
      right: 0.5rem;
      height: 0rem;
      width: 10.06rem;

      &_item {
        color: #ffffff;
        font-size: 0.88rem;
        line-height: 1.13rem;
        letter-spacing: 0.02rem;
        font-family: SVN-GilroyMedium;
        padding: 0.94rem 0;
        width: 9.94rem;
        border-bottom: 0.06rem solid rgba(255, 255, 255, 0.1);

        &:nth-of-type(1) {
          margin-top: 0.8rem;
        }

        &:nth-last-of-type(1) {
          border-bottom: none;
        }
      }
    }
  }

  .tagpop {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    backdrop-filter: blur(4px);
    background: rgba(0, 0, 0, 0.95);

    &_item {
      font-size: 1.75rem;
      line-height: 2.19rem;
      letter-spacing: 0.07rem;
      color: #ffffff;
      font-family: SVN-GilroyMedium;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
      padding: 1.81rem 0 1.88rem 3.75rem;
      border-bottom: 0.06rem solid rgba(255, 255, 255, 0.1);
      text-align: left;
    }
  }
}
</style>
