const getters = {
  wdHeight: (state) => state.common.windowHeight,
  wdWidth: (state) => state.common.windowWidth,
  isMoblie: (state) => state.common.isMoblie,
  Email: (state) => state.common.Email,
  keepRoutes: (state) => state.common.keepRoutes,
  token: (state) => state.user.token,
  avatar: (state) => state.user.Info.avatar,
  uid: (state) => state.user.Info.id,
  uuid: (state) => state.user.Info.uuid,
  name: (state) => state.user.Info.nickname,
  chain: (state) => state.user.Info.chain_address,
  showLog: (state) => state.common.showLog,
  showLogPop: (state) => state.common.showLogPop,
  showsign: (state) => state.user.showsign,
  signinfo: (state) => state.user.signinfo,
  hasPop: (state) => state.common.hasPop,
  userInfo: (state) => {
    if (!state.user.Info.id) {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
    return state.user.Info
  },
  inviteCode: (state) => state.common.inviteCode,
  taskData: (state) => state.user.taskData,
  metaObj: (state) => state.user.metaObj,
  walletObj: (state) => state.user.walletObj,
  SPK: (state) => state.user.SPK,
  logType: (state) => state.user.Info.logType,
  message: (state) => state.user.message,
  deviceId: (state) => state.common.deviceID,
  firstLog: (state) => state.user.Info.is_new_register,
  logState: (state) => state.user.logState,
  disconnect_ton: (state) => state.user.disconnect_ton,
}

export default getters
