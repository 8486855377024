import Vue from 'vue';
import Confirm from './index.vue';

const ConfirmBox = Vue.extend(Confirm);

Confirm.install = function(data){
    let instance = new ConfirmBox({
        data
    }).$mount()

    document.body.appendChild(instance.$el)
    return instance.show();
}

export default Confirm