import { ethers , providers , utils  } from "ethers";
import { Message } from 'element-ui'
import store from '@/store/index'
import usdtABI from '@/lib/js/usdtABI.json'
import spkABI from '@/lib/js/spkABI.json'

function check(target){
     if(typeof window.ethereum == 'undefined')throw Message({message:'MetaMask is unInstalled!',type:'warning',customClass:'myMessage'})
}

function str2Hex(str) {
    let result = '';
    for (let i=0; i<str.length; i++) {
        result += str.charCodeAt(i).toString(16);
    }
    return result;
}

export class MetaEthers {
    static COIN_TYPE ={ BNB:'BNB' ,SPK:process.env.VUE_APP_SPK_ADDRESS ,USDT:process.env.VUE_APP_UTSD_ADDRESS }
    static #provider = null ;
    static #account = null ;
    constructor(isLog=false){
        // if(MetaEthers.#provider)return Message({message:'exist provider!',type:'error',showClose:true})
        if(!MetaEthers.#provider)MetaEthers.#provider = new ethers.providers.Web3Provider(window.ethereum);
        this.setAccounts()
    }

    // @check
    async setAccounts(){
        MetaEthers.#account = (await MetaEthers.#provider.send("eth_requestAccounts", []))[0]
        if(store.state.user.userInfo&&MetaEthers.#account!== store.state.user.userInfo.chain_address){
            Message({message:'Account inconsistency!',type:'warning',customClass:'myMessage'})
            this.destory()
            // store.commit('user/logout');
            throw new Error('Account inconsistency')
        }else{
            // console.log(MetaEthers.#provider)
            return  MetaEthers.#account;
        }
        
    }

    async sign(message){
        if(!MetaEthers.#account){
            Message({message:'please Log!',type:'warning',customClass:'myMessage'})
            return this.setAccounts()
            // return store.commit('user/logout');
        }else{
            try{
                this.setAccounts()
            }catch(err){
                console.log('err--',err)
                return
            }
        }
        const signer = MetaEthers.#provider.getSigner();
        let signature = await signer.signMessage(message)
        console.log( utils.verifyMessage(message, signature).toLowerCase() )
        return signature;
    }

    async getWallet(type){
        if(!MetaEthers.#account){
            console.log('没有账户')
            await this.setAccounts();
            // return Message({message:'please Log!',type:'warning'})
            // return store.commit('user/logout');
        }
        if(!this.hasInType(type)){
            Message({message:'error type',type:'error',showClose:true,customClass:'myMessage'})
            throw new Error('error type')
        }
        if(MetaEthers.COIN_TYPE.BNB == MetaEthers.COIN_TYPE[type] ){
            let bigNumber =await  MetaEthers.#provider.getBalance(MetaEthers.#account)
            return ethers.utils.formatUnits(bigNumber, 18)
        }else{
            let Contract = new ethers.Contract(
                MetaEthers.COIN_TYPE[type],
                type=='SPK'?spkABI:usdtABI,
                MetaEthers.#provider.getSigner()
              );
            //   console.log(Contract)
              let value = await Contract.balanceOf(MetaEthers.#account);
              return ethers.utils.formatUnits(value, 18)
        }

    }

    async transfer(type,address,amount){
        console.log(type,address,amount,ethers.utils.parseUnits(String(amount), 18),MetaEthers.COIN_TYPE[type]);
        try{
            let value = await this.getWallet(type);
            if(Number(value) < Number(amount) )return Message({message:'transfer value greater than wallet value',type:'warning',showClose:true,customClass:'myMessage'})
            if(MetaEthers.COIN_TYPE.BNB == MetaEthers.COIN_TYPE[type] ){
                let tx = await MetaEthers.#provider.getSigner().sendTransaction({
                    gasLimit: 21000,
                    gasPrice: await MetaEthers.#provider.getGasPrice(),
                    to: address,
                    value: ethers.utils.parseEther(amount.toString())
                });
                console.log('----',tx);
            }else{
                let Contract = new ethers.Contract(
                    MetaEthers.COIN_TYPE[type],
                    type=='SPK'?spkABI:usdtABI,
                    MetaEthers.#provider.getSigner()
                );
                let payAmount =ethers.utils.parseUnits(String(amount), 18)
                let value = await Contract.approve(MetaEthers.#account,payAmount);
                console.log(value)
                let receipt = await MetaEthers.#provider.waitForTransaction(value.hash)
                console.log(receipt)
                if(receipt.status == 1)await Contract.transferFrom(MetaEthers.#account,address,payAmount);
                else throw receipt
            }
        }catch(err){
            throw err;
        }
        
    }

    hasInType(type){
        return MetaEthers.COIN_TYPE[type]
    }


    getProvider(){
        return MetaEthers.#provider;
    }

    getAccount(){
        return MetaEthers.#account;
    }

    destory(){
        // MetaEthers.#provider=null;
        MetaEthers.#account = null ;
    }
}
