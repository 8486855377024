import Vue from 'vue';
import walletLog from './index.vue'

const walletLogBox = Vue.extend(walletLog);

walletLogBox.install = function(data){
    let instance = new walletLogBox({
        data
    }).$mount()

    document.body.appendChild(instance.$el);
    return instance.show();
}

export default walletLogBox ;