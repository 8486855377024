import modules from './config/index.js'
import { Message , Notification } from 'element-ui'
import request from '@/utils/request'

export default async function({path,data,options,compelete}){
    // console.log('打印模块',modules)
    // console.log('参数---',data)
    if(!path)return Message('请输入路径')
    let [module,name]=path.split('/');
    // console.log(module,name)
    if( !modules[module] || !modules[module][name] )return Message(`请求路径不存在:${module}-${name}`)
    const { url , method } =modules[module][name]
    // console.log(url,method)
    let obj={
        url,
        method,
    }
    method=='GET' ? obj.params = data : obj.data = data
    
    return request(obj)
}
