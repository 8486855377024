import store from '@/store/index'
let ex=0,ey=0;

function dragDown(e){
    e.preventDefault();
    e.stopPropagation();
    // console.log('e----',e)
    ex=e.offsetX;
    ey=e.offsetY;
    e.currentTarget.addEventListener("mousemove",dragMove)
    e.currentTarget.addEventListener("mouseup",dragUp)
}

function dragMove(e){
    // console.log('e--move--',e)
    e.currentTarget.style.left=((e.clientX-ex)*100/store.state.common.windowWidth).toFixed(1)+'%';
    e.currentTarget.style.top=((e.clientY-ey)*100/store.state.common.windowHeight).toFixed(1)+'%';
}

function dragUp(e){
    e.currentTarget.removeEventListener("mousemove",dragMove);
}

function inserted(el,binding,vnode){
    el.addEventListener("mousedown",dragDown);
}


export default {
    bind(el,binding,vnode){
        console.log('测试---',binding,vnode)
    },
    inserted,
    update(){},
    unbind(el){
        el?.removeEventListener("mousedown",dragDown)
        console.log('----测试消失----')
    }
}