import { TonConnectUI, TonConnect } from '@tonconnect/ui'
// var manifestUrl = process.env.VUE_APP_BASE_URL + '/tonconnect-manifest.json'
console.log(window.location.origin + '/tonconnect-wallets.json')
const tonConnectUI = new TonConnectUI({
  manifestUrl: window.location.origin + '/tonconnect-manifest.json',
  connector: new TonConnect({
    //Wallets list, See source code. default: https://raw.githubusercontent.com/ton-blockchain/wallets-list/main/wallets-v2.json
    //You just need to reorder this list，Place the target wallet at the front of the list
    walletsListSource: window.location.origin + '/tonconnect-wallets.json',
  }),
  // manifestUrl: 'https://www.aicean.ai/tonconnect-manifest.json',
  // walletsListSource: window.location.origin + '/tonconnect-wallets.json',
})
export default tonConnectUI
