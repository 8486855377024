<template>
    <transition name="fade" >
        <div v-show="showFlag" class="box_wrapper flex flex-jc flex-ac" @click.stop @touchmove.stop >
            <div class="box flex flex-col flex-jfs flex-afs"  >
                <img class="sign_img" src="@/assets/default-avatar.png" />
                <span class="sign_title" >Welcome to AICEAN</span>
                <span class="sign_desc" >By connecting your wallet and using AICEAN, you agree to our Terms and Conditions</span>
                <div class="sign_bottom" >
                    <div v-loading="signFlag" element-loading-background="rgba(0,0,0,.6)" class="sign_bottom_btn flex flex-jc flex-ac" @click.stop="cancelHandler" >Cancel</div>
                    <div v-loading="signFlag" element-loading-background="rgba(0,0,0,.6)" class="sign_bottom_btn flex flex-jc flex-ac" @click.stop="loginHandler" >Accept</div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { disconnect, getAccount } from '@wagmi/core';
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            cacheDom:null,
            showFlag:false,
            signFlag: false,
            timer:null,
            resolve:null,
            reject:null,
        }
    },
    methods:{
        async loginHandler(){
             try{
                const { address, isConnected } = getAccount();
                if(!isConnected) throw 'please connect wallet';
                this.signFlag = true;
                const signature = await this.walletObj.sign( true );
                await this.$request({
                    path: 'user/bindWallet',
                    data: {
                        chain_address: address,
                        message: this.walletObj.getMessage(), 
                        signature: signature,
                    }
                })
                await this.$store.dispatch('user/updateInfo');
                this.$message({
                    message:'bind wallet success',
                    type:'success',
                    showClose:true,
                    customClass:'myMessage'
                })
            }catch(err){
                console.log('err-', err);
                this.$message({
                    message: err?.reason || err?.data?.message || err?.message || err?.msg || err ,
                    type:'error',
                    showClose:true,
                    customClass:'myMessage'
                })
                await disconnect();
            }
            this.signFlag = false
            this.resolve("confirm");
            this.hide()
        },
        async cancelHandler(){
            try{
                this.signFlag = true
                await disconnect();
                this.signFlag = false
                this.reject("cancel");
                this.hide();
            }catch(err){
                console.log('err-',err)
            }
        },
        show(){
            this.showFlag=true
            return new Promise((res,rej)=>{
                this.resolve=res
                this.reject=rej
            })
        },
        hide(){
            this.showFlag=false
            this.timer=setTimeout(() => {
                this.timer=null
                document.body.removeChild(this.$el);
                this.$destroy();
            }, 350);
        },
        beforeDestroy(){
            this.timer && clearTimeout(this.timer)
        },
    },
    computed: {
        ...mapGetters(['walletObj'])
    }
}
</script>

<style lang="scss" scoped >
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .25s;
    }
    .box{
    
        width:30.63rem;
        min-height:15.63rem;
        box-sizing: border-box;
        padding:2.03rem{
            top:2.81rem;
            bottom:1.5rem;
        }
        background:linear-gradient(135deg,transparent 0%,transparent 1%,rgba(0,0,0,.7) 1%,rgba(0,0,0,.7) 97.5%,transparent 97.5%);
        position:relative;
        // transition: all .35s ease;
        &::before{
            content:'';
            position:absolute;
            z-index:1000;
            top:0;
            left:0;
            width:2rem;
            height:2rem;
            background:no-repeat center/100% url(@/assets/boxTop.svg);
        }

        &::after{
            content:'';
            position:absolute;
            z-index:1000;
            bottom:0;
            right:0;
            width:1.6rem;
            height:1.6rem;
            background:no-repeat center/100% url(@/assets/boxBottom.svg);
        }

        &_wrapper{
            width:100%;
            height:100vh;
            background:rgba(255,255,255,.06) !important;
            backdrop-filter: blur(14px) !important;
            position: fixed;
            top:0;
            left:0;
            z-index:1002;
            // transition: all .35s ease;
            // pointer-events: none;
        }

        &_close{
            height:2.25rem;
            width:2.25rem;
            position:absolute;
            right:1rem;
            top:1rem;
            cursor: pointer;
        }

        &_logo{
            width:9.13rem;
            height:2rem;
            margin:0{
                bottom:1.25rem;
            }
        }

        &_item{
            width:26.56rem;
            height:3.94rem;
            color:#FFFFFF;
            font-size:1.25rem;
            line-height:1.56rem;
            box-sizing: border-box;
            padding:1.19rem 0;
            padding-left: 4.56rem;
            margin-bottom:1rem;
            // &:nth-of-type(1)  {
            //     background:no-repeat center/100% url(@/assets/metamask.png);
            // }

            &:nth-of-type(1) {
                background:no-repeat center/100% url(@/assets/walletconnect.png);
            }

        }
    }
    .sign{
        width: 100%;
        &_img{
            height: 8rem;
            width: 8rem;
            border-radius: 100%;
            margin-bottom: 1.5rem;
        }
        &_title{
            color:#FFFFFF;
            font-size:2.38rem;
            font-family: SVN-GilroyBold;
            margin-bottom:1.5rem;
            text-align: center;

        }
        &_desc{
            color:#FFFFFF;
            font-size:1.2rem;
            font-family: SVN-GilroyMedium;
        }
        &_bottom{
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 2rem;
            margin-top: 6rem;
            &_btn{
                height: 3.9rem;
                width: 100%;
                border-radius: .25rem;
                font-size: 1.25rem;
                font-family: SVN-GilroyBold;
                letter-spacing: .06rem;
                cursor: pointer;
                font-weight: bold;
                &:nth-of-type(1){
                    background: rgba(255,255,255,.3);
                    color: black;
                }
                &:nth-of-type(2){
                    background: linear-gradient(to right, #FE5900, #C51A24);
                    color: white;
                }
            }
        }
    }
</style>