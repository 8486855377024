module.exports={
    getNFTList:{
        url:'/api/frontend/nftMarket/list',
        method:'GET'
    },
    getPayList:{
        url:'/api/frontend/payMethod/config',
        method:'GET'
    },
    createOrder:{
        url:'/api/frontend/nftMarket/createOrder',
        method:'POST'
    },
    sellNFT:{
        url:'/api/frontend/mintNftMarket/sell',
        method:'POST'
    },
    cancelCell:{
        url:'/api/frontend/mintNftMarket/cancelSell',
        method:'POST'
    },
    buyNFT:{
        url:'/api/frontend/mintNftMarket/buy',
        method:'POST'
    },
    getMintList:{
        url:'/api/frontend/mintNftMarket/list',
        method:'GET'
    },
    getUserNFTList:{
        url:'/api/frontend/nft/userNftList',
        method:'GET'
    },
    getAddressNFTList:{
        url:'/api/frontend/nft/chainNfts',
        method:'GET'
    },
    getNFTDetail:{
        url:'/api/frontend/nft/nftDetail',
        method:'GET'
    },
    tansferPotion:{
        url:'/api/frontend/nft/repairPotiontransfer',
        method:'POST'
    },
    repairNFT:{
        url:'/api/frontend/nft/repair',
        method:'POST'
    },
    openBox:{
        url:'/api/frontend/nft/openMysterBox',
        method:'POST'
    },
    getRepairDetail:{
        url:'/api/frontend/nft/propDetail',
        method:'GET'
    },
    withdrawlNFT:{
        url:'/api/frontend/nft/mintToChain',
        method:'POST'
    }
}