<template>
    <transition name="fade" >
        <div class="bg flex flex-jc flex-ac" v-show="showFlag" @click.stop @touchmove.stop >
            <div class="confirm flex flex-col flex-jfs flex-ac"  >
                <div class="confirm_top flex flex-jsb flex-ac" >
                    <span class="confirm_top_txt" >Warning</span>
                    <img class="confirm_top_close" src="@/assets/popclose.svg" @click.stop="cancelHandler" />
                </div>
                <span class="confirm_msg flex flex-jfs flex-ac" ><img class="confirm_icon" :src="checkType" />{{message}}</span>
                <div class="confirm_bottom flex flex-jfe flex-ac" >
                    <div class="confirm_bottom_btn flex flex-jc flex-ac" @click.stop="cancelHandler" >Cancel</div>
                    <div class="confirm_bottom_btn flex flex-jc flex-ac" @click.stop="confirmHandler" >Confirm</div>
                </div>
            </div> 
        </div>
    </transition>
</template>

<script>
export default {
    data(){
        return {
            message:null,
            cb:null,
            type:null,
            duration:500,
            showFlag:false,
            resolve:null,
            reject:null,
            timer:null
        }
    },
    methods:{
        confirmHandler(){
            this.resolve("confirm")
            this.hide()
        },
        cancelHandler(){
            this.reject("cancel")
            this.hide()
        },
        show(){
            this.showFlag=true
            return new Promise((res,rej)=>{
                this.resolve=res
                this.reject=rej
            })
        },
        hide(){
            this.showFlag=false
            this.timer=setTimeout(() => {
                this.timer=null
                document.body.removeChild(this.$el);
                this.$destroy();
            }, 350);
        }
    },
    beforeDestroy(){
        this.timer && clearTimeout(this.timer)
    },
    computed:{
        checkType(){
            switch(Number(this.type)){
                case 1:return require('@/assets/popSuccess.svg');
                case 2:return require('@/assets/popWarning.svg');
                default:return require('@/assets/popError.svg');
            }
        }
    }
}
</script>

<style lang="scss" scoped >
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .35s;
    }
    .bg{
        position:fixed;
        left:0;
        top:0;
        z-index:9998;
        background: rgba(0,0,0,.6);
        backdrop-filter: blur(10px);
        height:100vh;
        width:100vw;
        overflow: hidden;
        transition: all .3s;
    }
    .confirm{
        width:26.44rem;
        min-height:8.69rem;
        background:rgba(63,63,63,0.25);
        border-radius: .38rem;
        border: 0.06rem solid rgba(255,255,255,0.1);
        backdrop-filter: blur(10px);
        padding:.94rem;
        box-sizing: border-box;
        
        & > * {
            width:24.56rem;
        }
        &_top{
            margin-bottom:1.06rem;
            &_txt{
                font-size:1.13rem;
                line-height:1.44rem;
                font-family: SVN-Gilroy;
                color:#FFFFFF;
            }
            &_close{
                width:1.38rem;
                height:1.38rem;
                cursor: pointer;
            }
        }

        &_icon{
            height:1.44rem;
            width:1.44rem;
            margin-right:.63rem;
        }

        &_msg{
            font-size:1.13rem;
            line-height:1.44rem;
            color:#FFFFFF;
            font-family: SVN-GilroyMedium;
        }

        &_bottom{
            margin-top:1rem;
            &_btn{
                width:4.69rem;
                height:2rem;
                border-radius: .25rem;
                font-size:.88rem;
                line-height:1.13rem;
                color:#FFFFFF;
                font-family: SVN-Gilroy;
                cursor: pointer;
                &:nth-of-type(1){
                    margin-right:.63rem;
                    border:.06rem solid rgba(227, 80, 0, 1);
                    background: rgba(247, 105, 0, 0.11);
                }

                &:nth-of-type(2){
                    background:linear-gradient(to right,rgba(254, 89, 0, 1),rgba(197, 26, 36, 1));
                    border:0.06rem solid rgba(0, 0, 0, 0.31);
                }
            }
        }
    }
</style>