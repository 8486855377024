var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"boxBg",staticStyle:{"height":"100vh","width":"100vw","position":"fixed","z-index":"1000","pointer-events":"none"}},[_c('el-tooltip',{staticClass:"box_img_wrapper",attrs:{"content":"You Tasks "}},[_c('img',{staticClass:"box_img",staticStyle:{"pointer-events":"auto"},attrs:{"src":require("@/assets/task.png")},on:{"click":function($event){$event.stopPropagation();return _vm.changeFlag(arguments, true)}}})]),_c('div',{staticClass:"box flex flex-jc flex-ac",staticStyle:{"pointer-events":"auto"},style:({
      pointerEvents: _vm.showFlag ? 'auto' : 'none',
      visibility: _vm.showFlag ? 'visible' : 'hidden',
    })},[_c('div',{staticClass:"box_content flex flex-col flex-jfs flex-ac",style:([_vm.popStyle])},[_c('img',{staticClass:"box_content_shrink",attrs:{"src":require("@/assets/shrink.svg")},on:{"click":function($event){$event.stopPropagation();return _vm.changeFlag(arguments, false)}}}),_c('span',{staticClass:"box_content_title"},[_vm._v("The system will automatically issue the corresponding token at 0:00 according to the daily contribution point")]),_c('div',{staticClass:"box_content_data flex flex-jsb flex-ac"},_vm._l((_vm.typeList),function(item,index){return _c('div',{key:index,staticClass:"data flex flex-col flex-jfs flex-ac"},[_c('img',{staticClass:"data_icon",attrs:{"src":item.icon}}),_c('span',{staticClass:"data_num"},[_vm._v(_vm._s(_vm.numFilter(item.value)))]),_c('span',{staticClass:"data_title"},[_vm._v(_vm._s(item.title))])])}),0),_c('div',{ref:"tagWrapper",staticClass:"tag flex flex-jfs flex-ac"},[_vm._l((_vm.tagList),function(tItem,tIndex){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(
            !(
              tIndex == 0 &&
              tItem.key == 'new_user' &&
              !_vm.taskDetail.is_has_new_user_task
            )
          ),expression:"\n            !(\n              tIndex == 0 &&\n              tItem.key == 'new_user' &&\n              !taskDetail.is_has_new_user_task\n            )\n          "}],key:tIndex,ref:"tag",refInFor:true,staticClass:"tag_item",style:({
            fontFamily:
              _vm.tagIndex == tIndex ? 'SVN-GilroySemiBold' : 'SVN-Gilroy',
          }),on:{"click":function($event){$event.stopPropagation();return _vm.tagChange(tIndex)}}},[_vm._v(_vm._s(tItem.name))])}),_c('span',{staticClass:"tag_history",on:{"click":function($event){$event.stopPropagation();return _vm.jumpHistory.apply(null, arguments)}}},[_vm._v("History")]),_c('div',{staticClass:"tag_bar",style:({
            left: _vm.leftList[_vm.tagIndex].left,
            width: _vm.leftList[_vm.tagIndex].width,
          })})],2),_c('div',{staticClass:"item_wrapper flex flex-col flex-jfs flex-ac"},[_vm._l((_vm.taskDetail.quest),function(item){return _c('div',{key:item.id,staticClass:"item flex flex-jsb flex-ac"},[_c('div',{staticClass:"flex flex-ac"},[_c('img',{staticClass:"item_img",attrs:{"src":_vm.iconHandler(item)}}),_c('span',{staticClass:"item_txt"},[_vm._v(_vm._s(item.task_name))]),(item.total_num)?_c('span',{staticClass:"item_num"},[_vm._v(" "+_vm._s(item.complete_num)+"/"+_vm._s(item.total_num))]):_vm._e()]),_c('div',{staticClass:"flex flex-jc flex-ac"},[_c('span',{staticClass:"item_num"},[_vm._v("+"+_vm._s(item.point))]),_c('img',{staticClass:"item_icon",attrs:{"src":require("@/assets/medalOrange.svg")}}),_c('img',{staticClass:"item_check",attrs:{"src":item.is_complete
                  ? require('@/assets/taskCheckActive.svg')
                  : require('@/assets/taskCheck.svg')}})])])}),_c('i',{staticClass:"el-icon-loading",staticStyle:{"color":"#ffffff","font-size":"2rem","margin-top":"1rem"},style:({ opacity: _vm.loading ? 1 : 0 })})],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }