const files=require.context('./',false,/\.js$/);
const moudles={};

files.keys().forEach(item=>{
  // console.log(item)
  if(item=='./index.js')return
  let key=item.match(/\.\/(.*)\.js$/)[1];
  const file=files(item)
  moudles[key]=file
})

export default moudles;
