<template>
  <div class="bg flex flex-jc flex-ac" :style="[bg]" @click.stop="show = false">
    <div
      class="pop flex flex-col flex-jfs flex-ac"
      :style="{ transform: `scale(${show ? 1 : 0.05})` }"
      @click.stop=""
    >
      <div class="pop_top flex flex-jsb flex-ac">
        <span class="pop_top_title">Add to home screen</span>
        <img
          class="pop_top_icon"
          src="@/assets/popclose.svg"
          @click.stop="show = false"
        />
      </div>
      <div class="pop_footer_txt">
        In the Safari browser menu, tap the "Share" icon and select "Add to Home
        Screen" from the options to easily add the AIcean website to your home
        screen and access it anytime!
      </div>
      <div class="btn flex flex-jc flex-ac">
        <div class="btn_item1" @click="PushManager">Confirm</div>
        <div class="btn_item2" @click.stop="show = false">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceWorker',
  props: {},
  data() {
    return {
      time: null,
      show: false,
    }
  },
  model: {
    prop: 'show',
    event: 'change',
  },
  mounted() {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      if (window.localStorage.getItem('hidePushManager')) {
        console.log(window.localStorage.getItem('hidePushManager'))
      } else {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          // 设备已经将网站添加到主屏幕上
        } else {
          // 可以将网站添加到主屏幕上
          // 判断是否支持添加到主屏幕功能
          localStorage.setItem('hidePushManager', true)
          this.show = true
          // if ('serviceWorker' in navigator && 'PushManager' in window) {
          //   console.log('可以将网站添加到主屏幕上')
          //   this.show = true
          // }
        }
      }
    }
  },
  methods: {
    PushManager() {
      this.show = false
      // // 注册service worker
      // navigator.serviceWorker.register('sw.js').then(function () {
      //   // 安装service worker成功
      //   navigator.serviceWorker.ready.then(function (registration) {
      //     // 请求用户授权
      //     registration.pushManager
      //       .subscribe({
      //         userVisibleOnly: true,
      //       })
      //       .then(function () {
      //         // 生成应用图标
      //         var icon = '../../assets/SPK.png'
      //         // 添加到主屏幕
      //         registration.showNotification('AICEAN', {
      //           body: 'AICEAN',
      //           icon: icon,
      //         })
      //       })
      //       .catch((e) => {
      //         console.log(e)
      //       })
      //   })
      // })
    },
  },
  computed: {
    bg() {
      return {
        backdropFilter: `blur(${this.show ? 7 : 0}px)`,
        pointerEvents: this.show ? 'auto' : 'none',
        opacity: this.show ? 1 : 0,
      }
    },
  },
  watch: {
    show(nval) {
      console.log(nval)
      if (!nval) {
        window.localStorage.setItem('hidePushManager', true)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: space-around;
  position: relative;
  color: #fff;
  top: 2rem;
  z-index: 100;

  cursor: pointer;
  &_item1 {
    line-height: 2.69rem;
    height: 2.69rem;
    width: 10rem !important;
    border-radius: 0.38rem;
    background: linear-gradient(to right, #fe5900, #c51a24);
    border: 0.15rem solid rgba(0, 0, 0, 0.31);
  }
  &_item2 {
    line-height: 2.69rem;
    height: 2.69rem;
    width: 10rem !important;
    border-radius: 0.38rem;
    background: #333;
    border: 0.15rem solid rgba(0, 0, 0, 0.31);
  }
}
.bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  opacity: 0;
  transition: all 0.35s;
}
.pop {
  width: 23.88rem;
  height: calc(16.25rem - 0.12rem);
  border-radius: 0.94rem;
  background: rgba(33, 33, 33, 0.98);
  transform: scale(0.05);
  transition: all 0.2s;
  padding: 1.56rem;
  position: relative;
  cursor: pointer;

  & > div {
    width: inherit;
  }

  &_top {
    margin-bottom: 1.25rem;

    &_title {
      font-size: 1.38rem;
      line-height: 1.81rem;
      font-family: SVN-GilroySemiBold;
      color: #ffffff;
    }
  }

  &_center {
    margin-bottom: 1.56rem;
    &_item {
      margin-right: 1.56rem;

      &_icon {
        height: 3.44rem;
        width: 3.44rem;
        margin-bottom: 0.63rem;
      }
      &_name {
        font-size: 0.88rem;
        line-height: 1.13rem;
        font-family: SVN-Gilroy;
        color: #ffffff;
      }
    }
  }

  &_footer {
    border-radius: 0.5rem;
    background: rgba(15, 15, 15, 1);
    border: 0.06rem solid rgba(51, 51, 51, 1);
    padding: 0.56rem 0.63rem;

    &_txt {
      font-size: 1.3rem;
      // line-height: 1.25rem;
      font-family: SVN-GilroyMedium;
      color: #ffffff;
      text-align: left;
      word-break: break-all;
    }

    &_btn {
      font-size: 0.88rem;
      line-height: 1.13rem;
      font-family: SVN-GilroyMedium;
      color: #ffffff;
      background: #fc5701;
      border-radius: 1.13rem;
      padding: 0.53rem 0.94rem;
    }
  }
}
</style>
